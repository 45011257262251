import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="peacock">
    <path
      fill="#37b678"
      d="m16.75 16.8-.75.66-.8.7-6.56 5.77a3.686 3.686 0 0 1-5.81-4.25 3.63 3.63 0 0 1 2.19-2.02l9.02-3.05 1.21-.41.42.73.33.57Z"></path>
    <path
      fill="#fdde47"
      d="M5.108 20.634c.478-.276 1.956-.612 2.232-.134s-.754 1.59-1.232 1.866a1 1 0 0 1-1-1.732Z"></path>
    <path
      fill="#37b678"
      d="M28.99 23a3.69 3.69 0 0 1-5.63.93l-6.56-5.77-.8-.7-.75-.66.75-1.3.33-.57.42-.73 1.21.41 9.02 3.05a3.63 3.63 0 0 1 2.19 2.02 3.685 3.685 0 0 1-.18 3.32Z"></path>
    <path
      fill="#fdde47"
      d="M25.892 22.366c-.478-.276-1.508-1.388-1.232-1.866s1.754-.142 2.232.134a1 1 0 0 1-1 1.732Z"></path>
    <path
      fill="#058b48"
      d="M16 15v3l-.33.07-.47.09-1.15.23-8.64 1.73a3.669 3.669 0 0 1-2.58-.44 3.63 3.63 0 0 1 2.19-2.02l9.02-3.05 1.16.23.47.09Z"></path>
    <path
      fill="#00a653"
      d="M16 14v3l-.33.07-1.62.32-8.64 1.73a3.689 3.689 0 1 1 0-7.24l9.79 1.96Z"></path>
    <path fill="#fdd402" d="M4 14.5c.552 0 2 .448 2 1s-1.448 1-2 1a1 1 0 0 1 0-2Z"></path>
    <path
      fill="#058b48"
      d="M16.75 15.2 16 16.5l-.33.57-.42.73-1.2-.41-.36-.12-8.67-2.93a3.63 3.63 0 0 1-2.19-2.02 3.669 3.669 0 0 1 2.58-.44l9.79 1.96.64.56.16.14Z"></path>
    <path
      fill="#37b678"
      d="m16.75 14.2-1.5 2.6-1.56-.53-8.67-2.93a3.691 3.691 0 1 1 3.62-6.27l7.2 6.33Z"></path>
    <path
      fill="#fdde47"
      d="M6.108 8.634c.478.276 1.508 1.388 1.232 1.866s-1.754.142-2.232-.134a1 1 0 0 1 1-1.732Z"></path>
    <path
      fill="#058b48"
      d="m16.3 14.75-.73.42-.57.33-1.027 1.06-.933-1.06-.7-.8-5.77-6.56a3.718 3.718 0 0 1-.88-1.95 3.707 3.707 0 0 1 2.95.88l7.2 6.33.05.14Z"></path>
    <path
      fill="#00a653"
      d="m17.3 14.75-.73.42-.57.33-1.3.75-.66-.75-.7-.8-5.77-6.56a3.686 3.686 0 0 1 4.25-5.81 3.63 3.63 0 0 1 2.02 2.19l3.05 9.02Z"></path>
    <path
      fill="#fdd402"
      d="M10.866 4.608c.276.478.612 1.956.134 2.232s-1.59-.754-1.866-1.232a1 1 0 1 1 1.732-1Z"></path>
    <path
      fill="#058b48"
      d="M29.17 19.68a3.669 3.669 0 0 1-2.58.44l-8.64-1.73-1.15-.23-.47-.09L16 18v-3l.33-.07.47-.09 1.16-.23 9.02 3.05a3.63 3.63 0 0 1 2.19 2.02Z"></path>
    <path
      fill="#00a653"
      d="M31 15.5a3.692 3.692 0 0 1-4.41 3.62l-8.64-1.73-1.62-.32L16 17v-3l.8-.16 9.79-1.96A3.702 3.702 0 0 1 31 15.5Z"></path>
    <path fill="#fdd402" d="M28 16.5c-.552 0-2-.448-2-1s1.448-1 2-1a1 1 0 0 1 0 2Z"></path>
    <path
      fill="#058b48"
      d="M29.17 12.32a3.63 3.63 0 0 1-2.19 2.02l-8.67 2.93-.36.12-1.2.41-.42-.73-.33-.57-.75-1.3.75-.66.16-.14.64-.56 9.79-1.96a3.669 3.669 0 0 1 2.58.44Z"></path>
    <path
      fill="#37b678"
      d="m26.98 13.34-8.67 2.93-1.56.53-1.5-2.6.91-.8 7.2-6.33a3.707 3.707 0 0 1 2.95-.88 3.691 3.691 0 0 1 .67 7.15Z"></path>
    <path
      fill="#fdde47"
      d="M26.892 10.366c-.478.276-1.956.612-2.232.134s.754-1.59 1.232-1.866a1 1 0 0 1 1 1.732Z"></path>
    <path
      fill="#058b48"
      d="M26.31 6.19a3.718 3.718 0 0 1-.88 1.95l-5.77 6.56-.7.8-.96 1.09-1-1.09-.57-.33-.73-.42.41-1.21.05-.14 7.2-6.33a3.707 3.707 0 0 1 2.95-.88Z"></path>
    <path
      fill="#00a653"
      d="m24.43 8.14-5.77 6.56-.7.8-.66.75-1.3-.75-.57-.33-.73-.42.41-1.21 3.05-9.02a3.63 3.63 0 0 1 2.02-2.19 3.686 3.686 0 0 1 4.25 5.81Z"></path>
    <path
      fill="#fdd402"
      d="M22.866 5.608C22.59 6.086 21.478 7.116 21 6.84s-.142-1.754.134-2.232a1 1 0 1 1 1.732 1Z"></path>
    <path
      fill="#058b48"
      d="m20.62 4.91-1.96 9.79-.76 1.072-2.4-.272-.07-.33-.32-1.63 3.05-9.02a3.63 3.63 0 0 1 2.02-2.19 3.67 3.67 0 0 1 .44 2.58Z"></path>
    <path
      fill="#058b48"
      d="m16.89 13.54-.32 1.63-.07.33-2.326.255-.834-1.055-1.96-9.79a3.67 3.67 0 0 1 .44-2.58 3.63 3.63 0 0 1 2.02 2.19Z"></path>
    <path
      fill="#37b678"
      d="M16 .5a3.69 3.69 0 0 1 3.617 4.413L17.5 15.5h-3L12.383 4.913A3.69 3.69 0 0 1 16 .5Z"></path>
    <path fill="#fdde47" d="M17 3.5c0 .552-.448 2-1 2s-1-1.448-1-2a1 1 0 0 1 2 0Z"></path>
    <path
      fill="#3f6db4"
      d="M20 25.5c0 3.31-1.79 6-4 6s-4-2.69-4-6a8.336 8.336 0 0 1 .42-2.66 35.129 35.129 0 0 0 1.75-7.83 21.76 21.76 0 0 0 .12-2.28v-.02a1.035 1.035 0 0 1 .11-.21h3.2a2.123 2.123 0 0 1 .11.21v.02a21.76 21.76 0 0 0 .12 2.28 35.129 35.129 0 0 0 1.75 7.83A8.336 8.336 0 0 1 20 25.5Z"></path>
    <path
      fill="#457ebf"
      d="M18 14a3.015 3.015 0 0 1-.17 1.01 2.624 2.624 0 0 1-.37.68 1.724 1.724 0 0 1-.34.37l-.03.03-.16.44-.93 2.59-.92-2.56-.17-.47a1.456 1.456 0 0 1-.34-.36 2.522 2.522 0 0 1-.4-.72A3.015 3.015 0 0 1 14 14a2.847 2.847 0 0 1 .29-1.29 1.035 1.035 0 0 1 .11-.21 1.78 1.78 0 0 1 3.2 0 2.123 2.123 0 0 1 .11.21A3.018 3.018 0 0 1 18 14Z"></path>
    <path
      fill="#1f53a4"
      d="m16.12 15.831-.03.042.563.264.279.354L16 20.12l-.92-3.588.598-.677c.12-.127-.258-.514.322-.528.57-.014-.03.35.12.504Z"></path>
    <path
      fill="#fdde47"
      d="m16.12 16.06-.03.03.563.188.279.253L16 19.12l-.92-2.56.598-.483c.12-.09-.258-.367.322-.377.57-.01-.03.25.12.36Z"></path>
    <path
      fill="#1f53a4"
      d="M17.46 15.69a1.724 1.724 0 0 1-.34.37l-.03.03-.16.44a.201.201 0 0 1-.08-.04 1.32 1.32 0 0 0-.84-.29 1.305 1.305 0 0 0-.79.29.37.37 0 0 1-.14.07l-.17-.47a1.456 1.456 0 0 1-.34-.36l.03-.03a2.407 2.407 0 0 1 1.39-.5 2.565 2.565 0 0 1 1.47.49Z"></path>
  </svg>
);

export default IconLogo;
